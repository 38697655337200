import React, { useState, useEffect } from 'react';
import './Report.css'
import client from "./axios-config"

function MyComponent() {
  const [responseData, setResponseData] = useState([]);

  useEffect(()  => {
    const pathname = window.location.pathname;
    const idFromPathname = pathname.split('/')[2];

    async function fectapi(){
      try{
        const response=await client.post("/check_user_id", JSON.stringify({"user_id":idFromPathname}),  {headers:{
          'Content-Type': 'application/json'
        }})
        if (response.status==200){
          console.log("url is vaild")  
          if (pathname.split('/')[3]==="whatsapp" ){
            get_message_report_data(idFromPathname)
          }
          else if (pathname.split('/')[3]==="call" ){
            get_call_report_data(idFromPathname)  
          }
        else{
          console.log("eror")
          alert("Url is not vaild")
          return false
          // alert(response.msg)
        }}}
      catch (error){
        alert("Url is not vaild")
        console.log("eror")
        return false}}
    fectapi()
  }, []);



  async function get_call_report_data(idFromPathname){
    try{
      const response=await client.post("/create_call_report_excel", JSON.stringify({"user_id":idFromPathname}),  {headers:{
        'Content-Type': 'application/json'
      }})
      const data = response.data
      setResponseData(data?.data)
    }
    catch(error){
      alert(error)
    }}


  async function get_message_report_data(idFromPathname){
    try{
      const response=await client.post("/create_report_excel", JSON.stringify({"user_id":idFromPathname}),  {headers:{
        'Content-Type': 'application/json'
      }})
      const data = response.data
      setResponseData(data?.data)
    }
    catch(error){
      alert(error)
    }}




  return (
    <div className="container">
      <h2>Response Data</h2>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Weekday</th>
            <th>Pill Response</th>
          </tr>
        </thead>
        <tbody>
          {responseData.map((item, index) => (
            <tr key={index}>
              <td>{item.date}</td>
              <td>{item.time}</td>
              <td>{item.weekday}</td>
              <td>{item.pill_reponse.toString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}



export default MyComponent;
