import {BrowserRouter,Routes,Route} from "react-router-dom";
import Voice from "./Voice";
import Report from "./Report";
import Thankyou from "./Thankyou";


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/user/:id" element={<Voice />}></Route>
        <Route exact path="/report/:id/:name" element={<Report />}></Route>
        <Route exact path="/thankyou" element={<Thankyou />}></Route>
       </Routes>
    </BrowserRouter>
  );
}

export default App;
