import React, { useState, useRef } from 'react';
import './Voice.css'
function AudioRecorder3({handleChange, type }) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const[audiofile, setaudiofile]=useState(null)
  const mediaRecorder = useRef(null);
  const audioStream = useRef(null);

  const startRecording = async () => {
    try {
      audioStream.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(audioStream.current);
      mediaRecorder.current.ondataavailable = handleDataAvailable;
      mediaRecorder.current.onstop = handleRecordingStop;
      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing the microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setAudioChunks((prevChunks) => [...prevChunks, event.data]);
      setaudiofile(event.data)
     
    }
  };

  const handleRecordingStop = () => {
    setIsRecording(false);
    if (audioStream.current) {
      audioStream.current.getTracks().forEach((track) => track.stop());
    }
  };

  const handleNewRecording = () => {
    setAudioChunks([]); // Clear recorded audio chunks
  };

  const handleAudioSubmit = async () => {
    if (audioChunks.length > 0) {
      const audioURL = new Blob(audioChunks, { type: 'audio/wav' });
      const formData = new FormData();

      formData.append('audio', audioURL);
      handleChange(audiofile, type)
      try {
        
        // Handle api here
        window.location.href = '/thankyou'; 
      } catch (error) {
        console.error('Error sending audio:', error);
      }
    }
  };

  return (
    <div className="audio-recorder">
      {isRecording ? (
        <>
          <div>
            <div>
              <img src="https://gifdb.com/images/high/sound-bar-sound-wave-xi3hsaiw59ojqnak.gif" width={50} height={50} alt="" />
              <img src="https://gifdb.com/images/high/sound-bar-sound-wave-xi3hsaiw59ojqnak.gif" width={50} height={50} alt="" />
              <img src="https://gifdb.com/images/high/sound-bar-sound-wave-xi3hsaiw59ojqnak.gif" width={50} height={50} alt="" />
            </div>
            <p>Recording...</p>
            <button className='btn-voice' variant="danger" onClick={stopRecording}>
              Stop Recording
            </button>
          </div>
        </>
      ) : (
        <>
          {audioChunks.length === 0 && (
            <button className='btn-voice' variant="success" onClick={startRecording}>
              Start Recording
            </button>
          )}
          {audioChunks.length > 0 && (
            <div>
              <div>Recorded voice:</div>
              <audio controls>
                <source src={URL.createObjectURL(new Blob(audioChunks, { type: 'audio/wav' }))} />
              </audio>
              <div>
                <button className='btn-voice' onClick={handleAudioSubmit}>Submit</button>
                <span> &nbsp;</span>
                <button className='btn-voice' onClick={handleNewRecording}>Record Again</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AudioRecorder3;
